#homeHero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	min-height: 530px;

	@include breakpoint(medium) {
		min-height: 665px;
	}
	@include breakpoint(large) {
		min-height: 900px;
	}

	.background {
		background-size: cover;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		z-index: 5;
	}

	.buttons {
		max-width: $global-width;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		padding: 0 2rem;
		position: relative;
		z-index: 10;
	}
	a {
		background: $beige;
		border-left: 10px solid $black;
		display: inline-block;
		padding: 1.5rem 1rem;
		max-width: 394px;
		width: 100%;
		text-transform: uppercase;
		color: $black;
		margin-bottom: 3rem;
		@include fluid-prop(font-size, 15px, 25px);
		font-family: $montserrat;
		font-weight: 600;
		@include transition;

		&:hover {
			margin-left: 1rem;

			@include breakpoint(large) {
				margin-left: 2rem;
			}
		}
	}
}
section#paintings,
section#worksOnPaper,
section#installations,
section#worksOnPaper {
	z-index: 10;
	position: relative;
	padding: 4rem 1rem;

	@include breakpoint(medium) {
		padding: 6rem 2rem;
	}

	@include breakpoint(large) {
		padding: 8rem 3rem;
	}
	.swiper-slide {
		background: $white;
	}

	.container {
		max-width: $global-width;
		margin: 0 auto;
		img {
			max-height: 575px;
			margin: 0 auto;
			display: block;
			width: 100%;
			object-fit: contain;
		}
		.art-card {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			.info {
				width: 70%;
				display: flex;
				flex-direction: column;
				margin-right: 2rem;
			}
			h2,
			h3 {
				margin-top: 2rem;
				margin-bottom: 1rem;
				font-family: $didot;
				color: $black;
				text-transform: none;
				@include fluid-prop(font-size, 25px, 40px);
			}
			p {
				color: $gray;
			}
			a.button {
				margin: 2rem 0;
				max-width: 136px;
				text-align: center;
			}
		}
		.home-painting-control-right,
		.home-works-control-right {
			display: flex;
			flex-direction: row;
			justify-content: center;
			color: $light-gray;
			align-items: center;
			margin-top: 2.5rem;
			span {
				font-family: $montserrat;
				font-size: 16px;
				margin-right: 1rem;
			}
		}
	}
}
section#worksOnPaper {
	text-align: right;
	.info {
		align-items: flex-end;
	}
}

section#paintings,
section#worksOnPaper {
	background: $white;
}
section#installations {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 499px;
	margin-right: 0;
	margin-left: 0;

	@include breakpoint(medium) {
		min-height: 650px;
	}

	@include breakpoint(large) {
		min-height: 802px;
	}

	h2 {
		color: $black;
		font-family: $montserrat;
		line-height: 1.2;
		font-weight: 600;
		@include fluid-prop(font-size, 15px, 25px);
		text-transform: uppercase;
		@include transition;
		text-align: center;
		margin-bottom: 2rem;
	}

	a {
		max-width: 136px;
	}
}
