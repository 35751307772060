/**
 * Buttons
 **/

a.button,
button.button,
header#siteHeader nav ul li.button a,
#offCanvas nav ul li.button a,
.wp-block-button__link {
	padding: 0.75rem 2rem;
	font-weight: bold;
	background: transparent;
	color: $black !important;
	transition: all 0.3s ease;
	border: 2px solid $black;
	text-transform: uppercase;
	font-family: $montserrat;
	font-weight: 600;

	// hover states
	&:hover,
	&:focus,
	&:active {
		color: $white !important;
		background: $orange;
	}

	// active state - click
	&:active {
		color: $white !important;
		background: $orange;
	}
}

#offCanvas nav ul li.button a {
	color: $white;
	border: 2px solid $white;

	// hover states
	&:hover,
	&:focus,
	&:active {
		background: $black;
		border: 2px solid $black;
	}

	// active state - click
	&:active {
		border: 2px solid $black;
		background: $black;
	}
}

.wp-block-button__link {
	border-radius: 0;
}
