#notFoundContainer {
	background: url(../images/installations-background.jpg) no-repeat center center;
	background-size: cover;
	height: 100vh;
	min-height: 530px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.container {
		max-width: 500px;
		margin: 0 auto;

		p {
			margin: 3rem 0;
		}
	}
}
