div.contact {
	height: 100%;
	padding: 5rem 2rem 0;
	width: 100%;

	h3 {
		color: $white;
		text-align: center;
		margin: 2rem 0;
	}
	form#gform_1 {
		input,
		textarea {
			border: 1px solid $white;
			background: none;
			font-family: $montserrat;
			color: $white;
		}
		::placeholder {
			color: $white;
			opacity: 0.7;
			font-family: $montserrat;
		}
		input#gform_submit_button_1 {
			background: $white;
			padding: 1rem;
			width: 100%;
			color: $orange;
			cursor: pointer;
			border-radius: 0;
			@include transition;
			-webkit-appearance: none;

			&:hover {
				background: $black;
				color: $white;
			}
		}
	}

	h2.gform_submission_error {
		color: #c02b0a !important;
		text-align: left !important;
		font-size: 1rem !important;
	}
}
