$primary-color: #ff7703;
$secondary-color: #f0ebdd;
$tertiary-color: #a1fce3;
$quaternary-color: #ffffff;

$white: #ffffff;
$black: #000000;

$orange: $primary-color;
$beige: $secondary-color;
$teal: $tertiary-color;
$light-gray: #9a9a9a;
$gray: #6f6f6f;
