body.active {
	overflow-y: hidden;
}
#offCanvas {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 100vh;
	overflow-y: auto;
	position: fixed;
	top: 0;
	right: -1000px;
	width: 100%;
	max-width: 414px;
	background: $orange;
	min-height: 600px;
	z-index: 100000;
	border-left: 3px solid $beige;
	@include breakpoint(large) {
		max-width: 662px;
	}

	@include transition;

	&.active {
		right: 0;
	}

	.form-header {
		position: absolute;
		bottom: 4rem;
		transform: translateX(-50%);
		transition-delay: 0.5s;
		left: 50%;
		width: 100%;
		@include transition;
	}

	&.form .form-header {
		bottom: calc(100% - 8rem);
	}
	.controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 1rem;
		margin-top: 1rem;
		position: absolute;
		top: 1rem;
		width: 100%;
	}
	button {
		border: none;
		background: none;
		cursor: pointer;

		svg {
			path {
				@include transition;
				fill: $white;
			}

			&:hover path {
				fill: $gray;
			}
		}
	}
	nav {
		flex-direction: column;
		text-align: center;
		ul {
			flex-direction: column;
			li {
				a {
					color: $white;
					margin: 1.5rem 0;
					display: inline-block;
					&:hover {
						color: $gray;
					}
				}
			}
		}

		a.instagram {
			margin-top: 1rem;
			margin-left: 0;
			svg {
				path {
					fill: $white;
				}

				&:hover path {
					fill: $gray;
				}
			}
		}

		&.menu {
			padding-top: 4rem;
			visibility: hidden;
			height: 75%;
			min-height: 600px;
		}
	}
	h2 {
		font-family: $montserrat;
		line-height: 1.2;
		font-weight: 600;
		@include fluid-prop(font-size, 15px, 25px);
		color: $white;
		text-transform: uppercase;
		@include transition;
		text-align: center;
		margin-bottom: 1rem;

		&:hover {
			color: $gray;
		}
	}
	.contact {
		visibility: hidden;
		position: absolute;
		top: 3rem;
		height: 80%;
		min-height: 600px;
		@include transition;

		.menu {
			visibility: hidden;
		}
	}
}
#offCanvasBack {
	visibility: hidden;
}
#offCanvas.menu.active {
	.menu {
		visibility: visible;
	}
}
#offCanvas.menu.active.form {
	.menu {
		visibility: hidden;
	}
	#offCanvasBack {
		visibility: visible;
	}
	.contact {
		visibility: visible;
	}
}
#offCanvas.active.form {
	.contact {
		visibility: visible;
	}
	#offCanvasBack {
		visibility: visible;
	}
}

@include breakpoint(large, up) {
	#offCanvas #offCanvasBack {
		visibility: hidden !important;
	}
}

#offCanvas h2.contact-title {
	font-family: $montserrat;
	line-height: 1.2;
	color: $white;
	text-transform: uppercase;
	font-weight: 600;
	@include fluid-prop(font-size, 10px, 15px);
	&:hover {
		color: $white;
	}
}
