header#siteHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: $beige;
	padding: 1rem;
	position: fixed;
	top: 0;
	width: 100%;
	height: 92px;
	align-items: center;
	z-index: 100;

	@include breakpoint(medium, up) {
		padding: 2rem 3rem;
	}

	h1 {
		color: $black;
		text-transform: uppercase;
		@include transition;
		&:hover {
			color: $gray;
		}
	}
}
header#siteHeader,
#offCanvas {
	nav {
		display: flex;
		flex-direction: row;

		ul li.menu-item-119 > a {
			display: none;

			@include breakpoint(nav) {
				display: block;
			}
		}

		ul {
			display: flex;
			flex-direction: row;
			list-style: none;
			position: relative;

			li {
				a {
					color: $black;
					@include transition;
					text-transform: uppercase;
					font-family: $montserrat;
					font-weight: 600;
					font-size: .8rem;
					letter-spacing: 2px;
					margin: 0 2rem;

					&:hover {
						color: $gray;
					}
				}

				@include breakpoint(nav) {
					ul {
						display: none;
					}
				}

				&:hover ul {
					display: block;
					@include breakpoint(nav) {
						position: absolute;
						li {
							&:first-child {
								margin-top: 1rem;
							}
							background: $beige;
							margin: 0;
							text-align: left;
							padding: 1rem 0;
						}
					}
				}
			}
		}
		a.instagram {
			margin-left: 1rem;

			svg {
				path {
					@include transition;
				}

				&:hover path {
					fill: $gray;
				}
			}
		}

		#menu-menu {
			display: none;

			@include breakpoint(nav) {
				display: flex;
			}
		}
		#mobileContact,
		#mobileMenu {
			margin-left: 2rem;
			@include breakpoint(nav) {
				display: none;
			}

			svg {
				path {
					@include transition;
				}

				&:hover path {
					fill: $gray;
				}
			}
		}
	}
}
header#siteHeader {
	align-items: center;
}
