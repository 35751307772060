body {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

main {
	width: 100%;
	background: $white;
	position: relative;
	z-index: 10;

	&.main-content {
		padding-left: 1rem;
		padding-right: 1rem;

		@include breakpoint(medium) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		@include breakpoint(large) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}

	.inner,
	article {
		max-width: $global-width;
		width: 100%;
		margin: 0 auto;
		padding-bottom: 4rem;
	}
}

.archive,
.page {
	margin-top: 8rem;

	main .inner {
		padding-left: 1rem;
		padding-right: 1rem;

		@include breakpoint(medium) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		@include breakpoint(large) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}
}

.inner-slider {
	h2 {
		margin-top: 2rem;
		margin-bottom: 0.5rem;
		font-family: $didot;
		color: $black;
		text-transform: none;
		@include fluid-prop(font-size, 25px, 40px);
	}
	p {
		color: $gray;
		margin-bottom: 2rem;
	}
	img {
		max-height: 575px;
		margin: 0 auto;
		display: block;
		width: 100%;
		object-fit: contain;
	}
}
.inner-slider-thumbs {
	.swiper-wrapper {
		display: flex;
	}
	.slide-thumb {
		width: 112px;
		height: 76px;

		@include breakpoint(675px, up) {
			width: 188px;
			height: 131px;
		}
	}
}
.controls {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: 2rem;

	.inner-control-left,
	.inner-control-right {
		display: flex;
		flex-direction: row;
		justify-content: center;
		color: $light-gray;
		align-items: center;
		margin-top: 2.5rem;
		span {
			font-family: $montserrat;
			font-size: 16px;
			margin-right: 1rem;
		}
	}
}

textarea,
input {
	-webkit-appearance: none;
}

table {
	td {
		font-family: $montserrat;
		vertical-align: baseline;
		line-height: 1.2;
		color: $gray;
		letter-spacing: 2px;
	}
}
