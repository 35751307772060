/** --- General typography settings --- **/
$header-line-height: 1.2;
$header-margin-bottom: 0.5rem;
$header-font-weight: bold;
$header-color: $primary-color;

$body-font-weight: 400;
$paragraph-margin-bottom: 1rem;

$montserrat: montserrat, sans-serif;
$didot: linotype-didot, serif;

// Typography resets
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

h1 {
	font-family: $montserrat;
	line-height: 1.2;
	color: $black;
	font-weight: 600;
	@include fluid-prop(font-size, 15px, 25px);
}

h2,
h1.entry-title {
	font-family: $didot;
	line-height: 1.2;
	color: $black;
	font-weight: 700;
	@include fluid-prop(font-size, 25px, 40px);
}

h3 {
	font-family: $montserrat;
	line-height: 1.2;
	color: $light-gray;
	text-transform: uppercase;
	font-weight: 600;
	@include fluid-prop(font-size, 10px, 15px);
}

p {
	font-family: $montserrat;
	line-height: 1.2;
	color: $gray;
	letter-spacing: 2px;
	@include fluid-prop(font-size, 16px, 18px);
}

h1.entry-title {
	margin-bottom: 1rem;
}
