.footer-container {
	background: $orange;
	position: relative;
	z-index: 10;
	padding-top: 5rem;

	.footer-grid {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		flex-direction: column;
	}

	h3 {
		font-family: $montserrat;
		line-height: 1.2;
		font-weight: 600;
		@include fluid-prop(font-size, 15px, 25px);
		color: $white;
		text-transform: uppercase;
		@include transition;
		text-align: center;

		&:hover {
			color: $gray;
		}
	}
	a.instagram {
		margin-top: 1rem;
		margin-left: 0;
		svg {
			path {
				fill: $white;
			}

			&:hover path {
				fill: $gray;
			}
		}
	}
	p {
		color: $white;
		margin-top: 2rem;
		margin-bottom: 2rem;
		font-size: 1rem;
	}
}
